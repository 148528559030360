<template>
    <div>
        <smallbutton :toplabel="buttontext" @click="startAdd()" v-if="showButton" :buttonclass="buttonclass"/>
        <linkbutton :toplabel="buttontext" @click="startAdd()" v-if="!showButton"/>
        <!--  MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <form ref="balform">
                    <label class="titlelabel">{{ title }}</label>
                    <br/>
                    <br>
                    <div v-if="!isNew">
                        <label class="entryLabel" for="">{{timeitem.StudentName}}</label>
                    </div>
                    <div v-if="!isNew">
                        <label class="entryLabel" for="">{{CourseName1}}</label>
                    </div>
                    <br>
                    <div class="entry" v-if="isNew">
                        <div class="entryTextDiv">
                            <label class="entryLabel" for="">Student</label>
                        </div>
                        <select name="" id="" class='entrySelect' v-model="timeitem.studentId" required>
                            <option v-for="s in studentList" :key="s.id" :value="s.id" >
                                {{s.name}}
                            </option>
                        </select>
                    </div>
                    <div class="entry" v-if="isNew">
                        <div class="entryTextDiv">
                            <label class="entryLabel" for="">Course</label>
                        </div>
                        <select name="" id="" class='entrySelect' v-model="timeitem.courseId" required>
                            <option v-for="s in courseList" :key="s.id" :value="s.id" >
                                {{s.name}}
                            </option>
                        </select>
                    </div>
                    <formentry   label="Course Date"  inputtype="date" v-model="selectedTimeData.enteredCourseDate" :isrequired="trueParam" />
                    <formentry   label="Course Start Time"  inputtype="time" v-model="selectedTimeData.enteredCourseStartTime" :isrequired="trueParam" />
                    <formentry   label="Course End Time"  inputtype="time" v-model="selectedTimeData.enteredCourseEndTime" :isrequired="trueParam" />
                    <formentry   label="BTW Minutes" v-if="!showBTWDropDown && showBTW"  v-model="selectedTimeData.enteredBTWMinutes" :isrequired="trueParam" />

                    
                     


                    <div class="entry" v-if="showBTWDropDown && showBTW">
                        <div class="entryTextDiv">
                            <label class="entryLabel" for="">BTW Minutes</label>
                        </div>
                        <select name="" id="" class='entrySelect' v-model="selectedTimeData.enteredBTWMinutes" required>
                            <option v-for="s in btwMinutesList" :key="s.id" :value="s.id" >
                                {{s.name}}
                            </option>
                        </select>
                    </div>

                    <div v-if="isComboSequence">
                        <br>
                        <div v-if="!isNew">
                            <label class="entryLabel" for="">{{CourseName2}}</label>
                        </div>
                        <formentry   label="Course Start Time"  inputtype="time" v-model="selectedTimeData.enteredCourseStartTime2" :isrequired="trueParam" />
                        <formentry   label="Course End Time"  inputtype="time" v-model="selectedTimeData.enteredCourseEndTime2" :isrequired="trueParam" />
                        <formentry   label="BTW Minutes" v-if="!showBTWDropDown && showBTW"  v-model="selectedTimeData.enteredBTWMinutes2" :isrequired="trueParam" />
                        <div class="entry" v-if="showBTWDropDown && showBTW">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">BTW Minutes</label>
                            </div>
                            <select name="" id="" class='entrySelect' v-model="selectedTimeData.enteredBTWMinutes2" required>
                                <option v-for="s in btwMinutesList" :key="s.id" :value="s.id" >
                                    {{s.name}}
                                </option>
                            </select>
                        </div>
                    </div>



                    <formentry   label="Note" inputtype="text"  v-model="selectedTimeData.note" :isrequired="noteCharCount>0" :minlength="noteCharCount" />

                    <div>
                        <label for="" class="required">{{requiredFieldLabel}}</label>
                    </div>
    
                    
                    <smallbutton  @click="save()" toplabel="Save" />
                    <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                    <div>
                        <label class="responsemessage">{{responseMessage}}</label>
                    </div>
                    
                </form>
                
                <confirmtimeconflict ref="confirmsubmittime"  :timeConflictList="timeConflictList"
                            @yes="finishSubmit()" :redText="paramTrue" />
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import datamethods from '../../services/datamethods.js'
    import {mapFields} from 'vuex-map-fields'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import linkbutton from '../controls/linkbutton.vue'
    import actionmodal from '../controls/actionmodal.vue'
    import formentry from '../controls/entry.vue'
    import confirmtimeconflict from '../actions/confirmtimeconflict.vue'
    export default {
        name:'updatetimeitem',
        components:{
            smallbutton,
            actionmodal,
            formentry,
            linkbutton,
            confirmtimeconflict
        },
        props:{
            timeitem:{
                type:Object
            },
            showButton:{
                type:Boolean,
                default:false
            },
            buttonclass:{
              type:String,
              default:"button buttonprimary"
            },
            studentList:{
                type:Array
            },
            courseList:{
                type:Array
            }
        },
        mixins:[datamethods,format],
        data(){
            return{
                insave:false,

                responseMessage:'',
                pleaseWait:false,
                
                requiredFieldLabel:'* required',
                trueParam:true,

                selectedTimeData:{
                    enteredCourseDate:null,
                    enteredCourseStartTime:null,
                    enteredCourseEndTime:null,
                    enteredBTWMinutes:null,
                    enteredCourseStartTime2:null,
                    enteredCourseEndTime2:null,
                    enteredBTWMinutes2:null,
                    note:null
                },
                timeConflictList:[],
                showBTW:false,
                isComboSequence:false
                
            }
        },
        computed:{
            CourseName1(){
                if(this.timeitem.productName!=null){
                    return this.timeitem.productName
                }else if(this.isComboSequence){
                    return 'Practice Range Time'
                }else{return ''}
            },
            CourseName2(){
                if(this.timeitem.productName2!=null){
                    return this.timeitem.productName2
                }else if(this.isComboSequence){
                    return 'Practice Road Time'
                }else{return ''}
            },
            showBTWDropDown(){
            
                if(window.SHOW_BTW_DROP_DOWN==undefined){
                    return false
                }else{
                return window.SHOW_BTW_DROP_DOWN
                }
            },
            ...mapFields([
                'btwMinutesList'
    
            ]),
            title(){
                if(this.isNew){return "Add Time Item"}
                else{return "Update Time Item"}
            },  
            buttontext(){
                if(this.isNew){return "Add Time Item"}
                else{return "Update"}
            },
            isNew(){
                if(this.timeitem!=undefined){
                    return this.timeitem.ID<=0
                }
                else{
                    return true
                }
            },
            noteCharCount(){
                
                if(window.REQUIRE_X_CHAR_NOTE==undefined){return 0}
                else{return window.REQUIRE_X_CHAR_NOTE}
            },
            warnAboutTimeConflicts(){
                if(window.WARN_ABOUT_TIME_CONFLICTS==undefined){return false}
                else{return window.WARN_ABOUT_TIME_CONFLICTS}
            },
        },
        mounted(){
           
        },
        methods:{
            
            beforeAPI(){
                this.pleaseWait=true
                this.responseMessage=''
            },
            
          
            startAdd(){
                this.insave=false
               this.selectedTimeData.enteredCourseDate= this.$options.filters.formatDateForInput(this.timeitem.enteredCourseDate)
               this.selectedTimeData.enteredCourseStartTime= this.$options.filters.formatTimeForInput(this.timeitem.enteredCourseStartTime)
               this.selectedTimeData.enteredCourseEndTime= this.$options.filters.formatTimeForInput(this.timeitem.enteredCourseEndTime)
                this.selectedTimeData.enteredBTWMinutes= this.timeitem.enteredBTWMinutes
                this.selectedTimeData.enteredCourseStartTime2= this.$options.filters.formatTimeForInput(this.timeitem.enteredCourseStartTime2)
               this.selectedTimeData.enteredCourseEndTime2= this.$options.filters.formatTimeForInput(this.timeitem.enteredCourseEndTime2)
                this.selectedTimeData.enteredBTWMinutes2= this.timeitem.enteredBTWMinutes2
                this.selectedTimeData.note = this.timeitem.timeNotes_Course

                this.checkProcedure()
                this.$refs.mymodal.openModal()
            },
            checkProcedure(){
                let id = this.timeitem.procedureSequenceId
                var btw =false
                var combo = false
                this.courseList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if(comp.id==id){
                       
                        btw=comp.showBTW
                        combo=comp.isComboSequence
                    }
                }
                this.showBTW=btw
                this.isComboSequence=combo
            },
            /* async save(){
               let req = {
                    productGroup:this.productGroup
                }
                 this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'addtimeitem')
                //handle response
                if(response.Successful){
                    this.$emit('saved')
                    this.$refs.mymodal.closeModal()
                    
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            }, */
            async save(){
                if(this.insave){return}
                this.responseMessage=null
                //if(!this.$refs.balform.checkValidity()){return}
               
                    if(this.selectedTimeData.enteredCourseStartTime==null || this.selectedTimeData.enteredCourseEndTime==null){
                        this.responseMessage="Please enter course start and end times before submitting"
                        return
                    }
                    var sdate = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime)
                    var edate = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime)
                    if( sdate > edate){
                        this.responseMessage = "Please enter a course end time AFTER your course start time"
                        return
                    }

                    var date1= new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime)
                    var date2 = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime)
                    
                    var courseMinutes = date1-date2
                    courseMinutes= (courseMinutes / (60000));
             
                    if(courseMinutes<this.selectedTimeData.enteredBTWMinutes){
                        this.responseMessage="Please enter BTW minutes less than or equal to the total course time."
                        return
                    }

                    //if its a combo, two items
                    if(this.isComboSequence){
                        var sdate2 = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime2)
                        var edate2 = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime2)
                        if( sdate2 > edate2){
                            this.responseMessage = "Please enter a road course end time AFTER your road course start time"
                            return
                        }

                        if((sdate2 < edate && sdate2 > sdate)||(sdate<edate2 && sdate >sdate2)){
                            this.responseMessage = "Please enter non-overlapping times"
                            return
                        }


                        var date3= new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime2)
                        var date4 = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime2)
                        
                        var courseMinutes2 = date3-date4
                        courseMinutes2= (courseMinutes2 / (60000));

                        if(courseMinutes2<this.selectedTimeData.enteredBTWMinutes2){
                            this.responseMessage="Please enter BTW minutes less than or equal to the total course time."
                            return
                        }
                    }

                    

                    //note
                    if(this.noteCharCount>0){
                        if (this.selectedTimeData.note==null){
                            this.responseMessage="Please enter a note of at least " + this.noteCharCount + " characters."
                            return
                        }
                        if (this.selectedTimeData.note.length< this.noteCharCount){
                            this.responseMessage="Please enter a note of at least " + this.noteCharCount + " characters."
                            return
                        }

                    } 

                    this.insave=true

                    //if we are warning about time conflicts then do it here. Then we'll do the actual save if its confirmed.
                    if(this.warnAboutTimeConflicts){this.checkForTimeConflicts();return;}

                    let req = {
                        ProcedureInstance:this.timeitem,
                        timeData:this.selectedTimeData
                    }
                    this.$store.dispatch('beforeAPI');
                    var response = await this.callAPI(req,'savetime')
                    //handle response
                    if(response.Successful){
                        this.insave=false
                        
                        this.$emit('refresh')
                        this.$refs.mymodal.closeModal()      
                    }else{
                        this.insave=false
                        this.responseMessage = response.Message
                    }
                    this.pleaseWait=false; 
        
            },
            async checkForTimeConflicts(){
                let req = {
                    ProcedureInstance:this.timeitem,
                    timeData:this.selectedTimeData,
                
                }
                this.$store.dispatch('beforeAPI');
        
                var response = await this.callAPI(req,'checkprocedureinstancetime')
                //handle response
                if(response.Successful){
                    //if no time conflicts, just go ahead and finish the submit
                    if(response.timeConflictList.length==0){this.finishSubmit(); return;}

                    this.insave=false

                    //show the conficts
                    this.timeConflictList= response.timeConflictList

                    let mythis = this
                    this.timeConflictList.forEach(myFunction2)
                    function myFunction2(item, index, arr) {
                        let comp=arr[index]
                        comp.evalStartTime=mythis.$options.filters.formatTimeForInput(comp.evalStartTime)
                        comp.evalEndTime=mythis.$options.filters.formatTimeForInput(comp.evalEndTime)
                    }

                    this.$refs.confirmsubmittime.Show()
                
                            
                }
                this.pleaseWait=false; 
            },
           async finishSubmit(){
                let req = {
                        ProcedureInstance:this.timeitem,
                        timeData:this.selectedTimeData
                    }
                    this.$store.dispatch('beforeAPI');
                    var response = await this.callAPI(req,'savetime')
                    //handle response
                    if(response.Successful){
                        this.insave=false 
                        this.$emit('refresh')
                        this.$refs.mymodal.closeModal()      
                    }else{
                        this.insave=false
                        this.responseMessage = response.Message
                    }
                    this.pleaseWait=false; 
           }
           
            
        }
    }
    </script>
    
    <style>
    .entrySelect{
        width:240px;
    }
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    