<template>
    <div class="homeDiv">
        <!-- <div class="buttons">
            <div>
                <smallbutton toplabel="Go to Time" buttonclass="buttonprimary widebutton" @click="gotime()" />
            </div>
        </div> -->
       <!--  <div class="leftjustified margin10">
            <linkbutton :toplabel="lookuptogglelabel" @click="togglelookup()"/>
        </div> -->
        <div class="filter horizFlex wrap " v-on:keyup.enter="filterTPR" v-if="showLookup">
            <p class="filterlabel">Lookup</p>
            <input class="filterfield" type="date" v-model="tprFilter.date1"/>
            <p class="filterlabel">to</p>
            <input class="filterfield" type="date" v-model="tprFilter.date2"/>
            
            <select name="" id="" class='filterselect' v-model="tprFilter.locationId" required @change="selectLocation()">
                <option v-for="loc in locationList" :key="loc.id" :value="loc.id" >
                    {{loc.name}}
                </option>
            </select>
            <select name="" id="" class='filterselect' v-model="tprFilter.studentId" required >
                <option v-for="s in studentList_tpr" :key="s.id" :value="s.id" >
                    {{s.name}}
                </option>
            </select>
            <select name="" id="" class='filterselect' v-model="tprFilter.courseId" required >
                <option v-for="c in courseList" :key="c.id" :value="c.id" >
                    {{c.name}}
                </option>
            </select>
            <!-- select name="" id="" class='filterselect' v-model="tprFilter.instructorId" required >
                <option v-for="c in instructorList" :key="c.id" :value="c.id" >
                    {{c.name}}
                </option>
            </select> -->
            <div v-if="showStartDate" class="horizFlex">
                <p >Start Date</p>
                <input type="date" v-model="tprFilter.studentStartDate" class='filterselect' /> 
            </div>
            <input type="checkbox" v-model="tprFilter.includePosted"/>
            <p>Include Posted</p>
            <smallbutton toplabel="Go" @click="filterTPR" buttonclass="buttonprimary narrowbutton"/>
            <smallbutton toplabel="Reset" buttonclass="buttonprimary narrowbutton" @click="resetfilter"/>

        </div>
        <div class="margin10" v-if="pleaseWait">
                <label class="pleasewait">Please Wait...</label>
        </div>
        
        <div class="horizFlexStretch">
            <div></div>
            <div class="vertFlex">
                <div class="horizFlexStretch" v-if="!showPost">
                    <div></div>
                    <!-- <addtimeitem :showButton="paramTrue" buttonclass="buttonprimary actionbutton" :courseList="courseList" :studentList="studentList"/> -->
                    <smallbutton toplabel="Post to TPR" buttonclass="buttonprimary actionbutton" @click="showPost=!showPost;responseMessage=null"/>
                </div>
                <div class="horizFlexStretch" v-if="showPost">
                    <div></div>
                    <div class="horizFlex">
                        <smallbutton toplabel="Send to TPR" buttonclass="buttonprimary actionbutton" @click="savePosts"/>
                        <smallbutton toplabel="Cancel" buttonclass="buttonprimary actionbutton" @click="showPost=false"/>
                    </div>
                    
                </div>

                <div class="leftjustified margin10" v-if="responseMessage!=null">
                    <label class="tprresponse" v-if="responseMessage!=null">{{ responseMessage }}</label>
                </div>
            </div>
           
            
        </div>
        <div >
            <div :class="headerclass()" >
 
                <linkbutton class="recordlabel bold" @click="setsort('name')" toplabel="Student"/>
                <linkbutton class="recordlabel bold" @click="setsort('date')" toplabel="Date Proficient"/>
                <linkbutton class="recordlabel bold" @click="setsort('posteddate')" toplabel="Posted to TPR"/>
                <linkbutton class="recordlabel bold" @click="setsort('score')" toplabel="Score"/>
                <linkbutton class="recordlabel bold" @click="setsort('hours')" toplabel="Total BTW Hours"/>
                <linkbutton class="recordlabel bold" @click="setsort('course')" toplabel="Event"/>
                <p class="recordlabel bold recordheader">Notes</p>    
                <div>
                    <p class="recordlabel bold recordheader" v-if="showPost">Post to TPR</p>  
                    <!-- <linkbutton v-if="showPost" class="recordlabel bold" @click="sortBy='notes'" toplabel="Post to TPR"/> -->
                </div>
            </div>
            <div class="vertScroll">
                <div v-for="g in sortedtprList" :key="g.id" :value="g.id" :class="recordClass(g)">
               
                    <!-- <p class="recordlabel">{{g.EmployeeName}}</p> -->
                    <linkbutton class="recordlabellink" @click="goToTimeForStudent(g.personId,g.studentIsActive)" :toplabel="g.EmployeeName"/>
                    <p class="recordlabel">{{formatDate(g.ItemDate) }}</p>
                    <p class="recordlabel">{{formatDate(g.postedDate) }}</p>
                    <p class="recordlabel">{{g.Score}}</p>
                    <p class="recordlabel">{{g.Hours}}</p>
                    <p class="recordlabel">{{g.JobName}}</p>
                    <p class="recordlabel">{{g.NoteText}}</p>
                    <div class="horizFlex" v-if="!showPost">
                        <div class="removeme" v-if="okToDeny(g)">
                            <confirm :showLink="paramTrue" :body="getRemoveString(g.EmployeeName)" title="Needs More Training" 
                                @yes="deny(g)" />
                        </div>
                    </div>
                    <div v-if="showPost">
                        <input type="checkbox" @change="setPost(g.ID)" class="approveCheck" v-if="okToPost(g)"/>
                    </div>
                </div>
            </div>
            
        </div>

    </div>
</template>
  
  <script>
  import {mapFields} from 'vuex-map-fields'
  import datamethods from '../../services/datamethods.js'
  import format from '../../format.js'
  import smallbutton from '../controls/button.vue'
  import linkbutton from '../controls/linkbutton'
  import confirm from '../actions/confirm.vue'
  import securityhelper from '../../services/securityhelper.js'
  export default {
      name:'tprview',
      mixins:[datamethods, format,securityhelper],
      components:{
          smallbutton,
          linkbutton,
          confirm,

      },
      data(){
          return{
            
            paramTrue:true,
            paramFalse:false,   
            
            showLookup:true,
            sortBy:'default',
            showPost:false,
            tprList_All:[],
            responseMessage:null,
            sortaccending:true
          }
      },
      computed:{
        sortedtprList(){
            if(this.sortBy=='default'){return this.tprList}

            var sort1 = this.sortaccending ? -1:1
            var sort2 = this.sortaccending ? 1:-1

          

            switch (this.sortBy) {
                case 'name':
                    function compare(a,b){
                        if (a.EmployeeName < b.EmployeeName)
                            return sort1
                        if(a.EmployeeName > b.EmployeeName)
                            return sort2
                        return 0
                    }
                    return this.tprList.sort(compare)
                    break;
                case 'date':
                    function compared(a,b){
                        if (a.ItemDate < b.ItemDate)
                            return sort1
                        if(a.ItemDate > b.ItemDate)
                            return sort2
                        return 0
                    }
                    return this.tprList.sort(compared)
                    break;
                case 'course':
                    function comparec(a,b){
                        if (a.JobName < b.JobName)
                            return sort1
                        if(a.JobName > b.JobName)
                            return sort2
                        return 0
                    }
                    return this.tprList.sort(comparec)
                    break;
                case 'score':
                    function comparee(a,b){
                        if (a.Score < b.Score)
                            return sort1
                        if(a.Score > b.Score)
                            return sort2
                        return 0
                    }
                    return this.tprList.sort(comparee)
                    break;
                case 'hours':
                    function comparef(a,b){
                        if (a.Hours < b.Hours)
                            return sort1
                        if(a.Hours > b.Hours)
                            return sort2
                        return 0
                    }
                    return this.tprList.sort(comparef)
                    break;
                default:
                    return this.tprList
                    break;
            } 
            

            
        },
       
        lookuptogglelabel(){
            if(this.showLookup){return "Hide Lookup"}
            else{return "Show Lookup"}
        },


     
          ...mapFields([
              
              'loggedInUser.isAdmin',
              'pleaseWait',
              'pleaseWaitText',
             'imageBasePath',
             'locationList',
             'loggedInUser',
             'tprFilter',
             'courseList',
             'studentList_tpr',
             'studentList',
             'instructorList',
             'tprList',
             'online',
             'filter',
             'includeInactiveStudents'
          ]),
          showStartDate(){
            if(window.SHOW_STARTDATE==undefined){return false}
            else{return window.SHOW_STARTDATE}
        },
        postCount(){
            var count=0
            this.tprList.forEach(myFunction2)
                    function myFunction2(item, index, arr) {
                    let inst=arr[index]
                    
                        if(inst.toPost) {count=count = 1}
                    
                }    
                return count
        }
        
      },
      props:{
          
          
          
      },
      methods:{
        setsort(s){
          //  console.log('setsort ' + s)
            if(s==this.sortBy){this.sortaccending=!this.sortaccending}
            else{
                this.sortBy=s
            }
        },
        async goToTimeForStudent(id, isactive){
            if(!isactive){ this.includeInactiveStudents=true}

            //update filter
            let today =new Date();
            var date1 =new Date().setDate(today.getDate()-180);
           // console.log('dt ' + date1)
           this.filter.InstructorID=this.loggedInUser.user_personId
            console.log(this.filter.InstructorID)
            this.filter.LocationID=this.tprFilter.locationId
            this.filter.StartDateTime=this.$options.filters.formatDateForInput(date1)
            this.filter.EndDateTime=this.$options.filters.formatDateForInputToday()
            this.filter.StudentID=id
            this.filter.procedureSequenceId=-1
            this.filter.studentGroupId=-1
            this.filter.studentStartDate=null

            //refresh the student list
            let req = {
                LocationID:this.filter.LocationID,
                includeInactive:this.includeInactiveStudents
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'students')
            //handle response
            if(response.Successful){
                 this.studentList=response.PersonList
                 var all = {id:-1, name:'--All Students--'}
                 this.studentList.unshift(all)
                 this.$emit('gotime')
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 

            
        },
        //POSTS
        
        setPost(id){
              //  console.log('appid ' + id)
                this.tprList.forEach(myFunction2)
                    function myFunction2(item, index, arr) {
                    let inst=arr[index]
                    if(inst.ID ==id){   
                        inst.toPost=!inst.toPost
                       // console.log('set post ' + inst.ID + ' ' + inst.toApprove)
                    } 
                }
                
                
            },  
            okToPost(inst){
                return inst.StatusText == "Ready To Post" 
            },
            okToDeny(inst){
                var ready = inst.StatusText=='Ready To Post' || inst.NoteText.includes('Time Is Not Approved')
                return ready && (!inst.JobName.includes('THEORY') && !inst.JobName.includes('Theory') )
            },
            async savePosts(){
                this.responseMessage=null
                if(!this.online){ return}
                
                if(this.postCount==0){
                    this.responseMessage="Please select at least one item to post to the TPR"
                    return
                }

                let req = {
                    TPRItemList:this.tprList,
                    employeeId:this.loggedInUser.user_personId,
                    TPRItemList_All:this.tprList_All
                    
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'posttpritems')
                //handle response
                if(response.Successful){
                    this.responseMessage = response.StringResponse
                    this.showPost=false
                    this.tprFilter.includePosted=true
                    this.filterTPR()
                    this.pleaseWait=false;
                }else{
                    this.responseMessage = response.Message
                }
            },

            async deny(inst){
                if(!this.online){ return}
            
                let req = {
                    TPRItem:inst
                    
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'denytpritem')
                //handle response
                if(response.Successful){
                    this.showApprove=false
                    this.filterTPR()
                    this.pleaseWait=false;
                }else{
                    this.responseMessage = response.Message
                }
            },

        formatDate(dt){
            if(dt==null){return ''}
           // console.log(dt)
            if(dt.includes('0001-01-01')){return ''}
            return this.$options.filters.formatDate(dt)
        },
        formatTime(dt){
            var ti = this.$options.filters.formatTime(dt)
            if(ti=='00:00 AM'){ti=''}
            return ti
        },
        formatHours(hrs){
            if(hrs==null){return ''}
            return Number(hrs).toFixed(2)
        },
        gotime(){
            this.$emit('gotime')
        },
        resetfilter(){
            console.log('reset tpr filter')
            this.tprFilter.date1=this.$options.filters.formatDateForInputToday()
            this.tprFilter.date2=this.$options.filters.formatDateForInputToday()
            this.tprFilter.studentId=-1
            this.tprFilter.locationId=this.loggedInUser.LocationId
            
            this.tprFilter.includeInactive=false
            this.tprFilter.studentStartDate=null
        },
        togglelookup(){
          //  console.log('toggle')
            this.showLookup=!this.showLookup
            localStorage.showLookup = this.showLookup
        },
        headerclass(){
            var myclass 
            myclass="tprrecord"
            return myclass
        },
        recordClass(g){
            var myclass 
            myclass="tprrecord"
            if(g.StatusText=='Removed'){
                myclass=myclass + ' tprrecordremoved'
            }else if(g.StatusText=='Error'){
                myclass=myclass + ' tprrecordissue'
            }else if(g.StatusText=='Posted'){
                myclass=myclass + ' tprrecordposted'
            }else{
                myclass=myclass + ' tprrecordregular'
            }


            return myclass
        },
        
        GradeButtonLabel(grade){
            if(grade.IsLocked){return "View Completed"}
            if(this.isBatchGrade(grade)){return "Grade GROUP"}
            else{return "Enter Grades"}
        },
        isBatchGrade(grade){
            return grade.StudentName.includes('GROUP ')
        },
        gradebuttonclass(grade){
            if(grade.isFailure){
                return "buttonred recordactionbutton"
            }
            else if(!grade.IsLocked){
                return "buttongreen recordactionbutton"
            }else{return "buttonprimary recordactionbutton"}
        },
        
        
        getRemoveString(name){
            return "Do you want to mark this TPR item as Needs More Training for " + name + "?"
        },
        async RemoveGrade(id){
            if(!this.online){this.handleOffline('removegrade'); return}

            
            let req = {
                id:id
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'removegrade')
            //handle response
            if(response.Successful){
                this.pleaseWait=false;
                this.filterTPR();
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
       
        
        formatDateTime(dt){
            return this.$options.filters.formatDateTime(dt)
        },
        async filterTPR(showunsub,firsttime,afterclockin){
            if(!this.online){ return}
           // console.log('getting fresh grades')
            let req = {
                tprFilter:this.tprFilter
    
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'tpritems')
            //handle response
            if(response.Successful){
                this.sortBy='default'
                 this.tprList=response.TPRItemList
                 this.tprList_All=response.TPRItemList_All
                 this.pleaseWait=false;

            }else{
                this.responseMessage = response.Message
            }
            
        },
        
     

         
          async getLocations(firsttime){
            if(!this.online){this.handleOffline('getloc');return}
            let req = {
                id:this.loggedInUser.user_personId
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'locations')
            //handle response
            if(response.Successful){
                 this.locationList=response.GradeLocations
                 this.pleaseWait=false; 
                 //var all = {id:-1,name: '--All Locations--'}
                 //this.locationListWithAll.unshift(all)
                 if(this.loggedInUser.LocationId>0 ){
                    this.tprFilter.locationId=this.loggedInUser.LocationId
                    this.selectLocation(firsttime);
                 }else{
                    this.tprFilter.locationId=-1
                    this.selectLocation(firsttime);
                 }
                
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          },
          async getStudents(){
            if(!this.online){this.handleOffline('getstu');return}
            let req = {
                LocationID:this.tprFilter.locationId,
                includeInactive:false
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'students')
            //handle response
            if(response.Successful){
                 this.studentList_tpr=response.PersonList
                 var all = {id:-1, name:'--All Students--'}
                 this.studentList_tpr.unshift(all)
                this.tprFilter.StudentIDstudentId=-1
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          },
          async getInstructors(){
            if(!this.online){this.handleOffline('getstu');return}
            let req = {
                LocationID:this.tprFilter.locationId
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'instructors')
            //handle response
            if(response.Successful){
                 this.instructorList=response.PersonList
                 var all = {id:-1, name:'--All instructors--'}
                 this.instructorList.unshift(all)
                this.tprFilter.instructorId=-1
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          },
          async getCourses(){
            if(!this.online){this.handleOffline('getcourse');return}
            let req = {
                LocationID:this.tprFilter.locationId
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'timecourses_tpr')
            //handle response
            if(response.Successful){
                 this.courseList=response.TimeEventList
                 var all = {id:-1, name:'--All Courses--'}
                 this.courseList.unshift(all)
                this.courseId=-1
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          },
          
          selectLocation(firsttime){
           // console.log('loc')
            this.getStudents();
            this.getInstructors();
            this.getCourses();
           
            
            this.filterTPR(true,firsttime);
           
            
          },
          start(){
            this.resetfilter()
            this.filterTPR()
            },
        },  
        
      mounted(){
        
        this.currentDate = this.$options.filters.formatDateForInputToday()

        var tmp2 = localStorage.showLookup
        //console.log('show ' + tmp2)
        if(tmp2!=undefined){
            this.showLookup=tmp2=="true"
        }

        if(this.tprFilter.locationId==-1 || this.locationList.length==0){
            this.getLocations(true);
            this.resetfilter()
            
        }else if(this.tprFilter.instructorId!=this.loggedInUser.user_personId){
            this.getLocations(true);
            this.resetfilter()
            this.filterTPR()
        }

      }
      
  }
  </script>
  
  <style>
  .homeDiv{
    display:grid;
    grid-template-rows: auto auto auto;
  }
  .buttons{
    display:flex;
    width:100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .filter{
    padding-left:10px;
  }
  .tprrecord{
    text-align:left;
    
    margin:5px;
    border-radius: 2px;
    padding:0px 0px 0px 5px;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr  2fr 1fr;
  }
  .tprrecordregular{
    background-color:var(--frame-color);
  }
  .tprrecordposted{
    background-color:rgb(209, 224, 209);
  }
  .tprrecordissue{
    background-color:rgb(240, 204, 158);
  }
  .tprrecordremoved{
    background-color:rgb(243, 191, 191);
  }
  .tprheader{
    text-align:left;
 
    margin:5px;
    border-radius: 2px;
    padding:0px 0px 0px 5px;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr  2fr 1fr;
  }
  
.recordactionbutton{

border: none;
border-radius:5px;
width:120px;
height:30px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;

}
.filterfield{
    height:30px;
    margin:5px;
    max-width:110px;
    font-size:inherit;
}
.filterselect{
    height:35px;
    margin:5px; 
    min-width: 125px;
    font-size:inherit;
}
.filterlabel{
    font-size:larger;
    margin:10px 0px 0px 0px;
    color:var(--text-color);
}
.removeme{
    margin-top:10px;
    margin-left:5px;
}
.redborder{
    border:2px red solid;
}
.tprresponse{
    white-space: pre-wrap;
    color:red;

}
.vertScroll{
    overflow-x:hidden;
    overflow-y:scroll;
    width:100%;
    height:480px;
  }
  .recordlabellink{
    text-align: left;
    margin:15px 0px 0px 5px;
}
.recordheader{
    margin:0px 0px 0px 0px;
}
  </style>

