<template>
    <div>
        <smallbutton :toplabel="buttontext" @click="startAdd()" v-if="showButton" :buttonclass="buttonclass"/>
        <linkbutton :toplabel="buttontext" @click="startAdd()" v-if="!showButton"/>
        <!--  MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <form ref="balform">
                    <label class="titlelabel">{{ title }}</label>
                    <br/>
                    <br>
                    <label class="entryLabel" for="">Select Location</label>
                    <optionbutton :itemList="locationList" @select="selectLocation" :initialSelectedId="locationId"/>
                    <div class="leftjustified" v-if="locationId>0">
                        <label class="entryLabel" for="">Select Course</label>
                        <optionbutton :itemList="courseList" @select="selectCourse"/>
                    </div>
                    
                    
              
                    
                    <div class="leftjustified" v-if="courseId>0">
                        <div v-if="ProcedureTypeList.length>1">
                            <label class="entryLabel" for="">Select Training Category</label>
                            <optionbutton :itemList="ProcedureTypeList" @select="selectProcedureType"/>
                        </div>
                        
            

                        <label class="entryLabel" for="">Select Training</label>
                        <optionbutton :itemList="procedureList" @select="selectprocedure"/>

                        <!-- <label class="entryLabel" for="">Select Students</label>
                        <optionbutton :itemList="studentList" @select="selectstudent"/> -->
                        
                        <!-- <div class="leftjustified" v-if="locationId>0">
                            <label class="entryLabel" for="">Select Instructor</label>
                            <optionbutton :itemList="instructorList" @select="selectinstructor"/>
                        </div> -->
                    </div>

                    <div class="entry" v-if="courseId>0">
                        <div class="entryTextDiv">
                            <label class="entryLabel" for="">Instructor</label>
                        </div>
                        <select name="" id="" class='entrySelect' v-model="instructorId" required>
                            <option v-for="s in instructorList" :key="s.id" :value="s.id" >
                                {{s.name}}
                            </option>
                        </select>
                    </div>


                    <div class="entry" v-if="courseId>0">
                        <div class="entryTextDiv">
                            <label class="entryLabel" for="">Student</label>
                        </div>
                        <select name="" id="" class='entrySelect' v-model="studentId" required @select="selectstudent(s.id,s.name)">
                            <option v-for="s in studentList" :key="s.id" :value="s.id" >
                                {{s.name}}
                            </option>
                        </select>
                    </div>


                    <formentry   label="Course Date"  inputtype="date" v-model="selectedTimeData.enteredCourseDate" :isrequired="trueParam" />
                    <formentry   label="Course Start Time"  inputtype="time" v-model="selectedTimeData.enteredCourseStartTime" :isrequired="trueParam" />
                    <formentry   label="Course End Time"  inputtype="time" v-model="selectedTimeData.enteredCourseEndTime" :isrequired="trueParam" />
                    <formentry   label="BTW Minutes" v-if="!showBTWDropDown && showBTW"  v-model="selectedTimeData.enteredBTWMinutes" :isrequired="trueParam" />

               
                    <div class="entry" v-if="showBTWDropDown && showBTW">
                        <div class="entryTextDiv">
                            <label class="entryLabel" for="">BTW Minutes</label>
                        </div>
                        <select name="" id="" class='entrySelect' v-model="selectedTimeData.enteredBTWMinutes" required>
                            <option v-for="s in btwMinutesList" :key="s.id" :value="s.id" >
                                {{s.name}}
                            </option>
                        </select>
                    </div>

                    <div v-if="isComboSequence">
                        <br>
                        <div v-if="!isNew">
                            <label class="entryLabel" for="">{{timeitem.productName2}}</label>
                        </div>
                        <formentry   label="Course Start Time"  inputtype="time" v-model="selectedTimeData.enteredCourseStartTime2" :isrequired="trueParam" />
                        <formentry   label="Course End Time"  inputtype="time" v-model="selectedTimeData.enteredCourseEndTime2" :isrequired="trueParam" />
                        <formentry   label="BTW Minutes" v-if="!showBTWDropDown && showBTW"  v-model="selectedTimeData.enteredBTWMinutes2" :isrequired="trueParam" />
                        <div class="entry" v-if="showBTWDropDown && showBTW">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">BTW Minutes</label>
                            </div>
                            <select name="" id="" class='entrySelect' v-model="selectedTimeData.enteredBTWMinutes2" required>
                                <option v-for="s in btwMinutesList" :key="s.id" :value="s.id" >
                                    {{s.name}}
                                </option>
                            </select>
                        </div>
                    </div>



                    <formentry   label="Note" inputtype="text"  v-model="selectedTimeData.note" :isrequired="noteCharCount>0" :minlength="noteCharCount" />

                    <div>
                        <label for="" class="required">{{requiredFieldLabel}}</label>
                    </div>
    
                    
                    <smallbutton  @click="save()" toplabel="Save" />
                    <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                    <div>
                        <label class="responsemessage">{{responseMessage}}</label>
                    </div>
                    
                </form>
                
                <confirmtimeconflict ref="confirmsubmittime"  :timeConflictList="timeConflictList"
                            @yes="finishSubmit()" :redText="paramTrue" />
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import datamethods from '../../services/datamethods.js'
    import {mapFields} from 'vuex-map-fields'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import linkbutton from '../controls/linkbutton.vue'
    import actionmodal from '../controls/actionmodal.vue'
    import formentry from '../controls/entry.vue'
    import optionbutton from '../controls/optionbutton.vue'
    import confirmtimeconflict from '../actions/confirmtimeconflict.vue'
    export default {
        name:'addtimeitem',
        components:{
            smallbutton,
            actionmodal,
            formentry,
            linkbutton,
            optionbutton,
            confirmtimeconflict
        },
        props:{
            
            showButton:{
                type:Boolean,
                default:false
            },
            buttonclass:{
              type:String,
              default:"button buttonprimary"
            },
        
         
        },
        mixins:[datamethods,format],
        data(){
            return{
                insave:false,

                responseMessage:'',
                
                requiredFieldLabel:'* required',
                trueParam:true,

                selectedTimeData:{
                    enteredCourseDate:null,
                    enteredCourseStartTime:null,
                    enteredCourseEndTime:null,
                    enteredBTWMinutes:null,
                    enteredCourseStartTime2:null,
                    enteredCourseEndTime2:null,
                    enteredBTWMinutes2:null,
                    note:null
                },
                locationId:0,
                courseId:-1,
                procedureTypeId:0,
                procedureId:-1,
                studentId:-1,
                studentName:null,
                instructorId:-1,

                courseList:[],
                studentList:[],

                ProcedureTypeList:[],
                procedureList:[],
                
                isComboSequence:false,
                showBTW:false,
                timeConflictList:[],
            }
        },
        computed:{
            allowConflictingTime(){
                if(window.ALLOW_CONFLICTING_TIME==undefined){return true}
                else{return window.ALLOW_CONFLICTING_TIME}
            },
            showBTWDropDown(){
            
            if(window.SHOW_BTW_DROP_DOWN==undefined){
                return false
            }else{
            return window.SHOW_BTW_DROP_DOWN
            }
        },
            ...mapFields([
             'locationList',
             'pleaseWait',
             'btwMinutesList',
             'loggedInUser'
    
            ]),
            title(){
                return "Add Time Item"
              
            },  
            buttontext(){
                return "Add Time Item"
               
            },
            isNew(){
                if(this.timeitem!=undefined){
                    return this.timeitem.ID<=0
                }
                else{
                    return true
                }
            },
            noteCharCount(){
                
                if(window.REQUIRE_X_CHAR_NOTE==undefined){return 0}
                else{return window.REQUIRE_X_CHAR_NOTE}
            },
            warnAboutTimeConflicts(){
                if(window.WARN_ABOUT_TIME_CONFLICTS==undefined){return false}
                else{return window.WARN_ABOUT_TIME_CONFLICTS}
            },
        },
        mounted(){
           
        },
        methods:{
            
            beforeAPI(){
                this.pleaseWait=true
                this.responseMessage=''
            },
            
          
            startAdd(){
                this.insave=false
                this.isComboSequence=false
                this.locationId=this.loggedInUser.LocationId
                this.courseId=-1
                this.procedureTypeId=0
                this.procedureId=-1
                this.studentId=-1
                this.studentName=null
                this.instructorId=-1
                this.selectedTimeData={
                    enteredCourseDate:null,
                    enteredCourseStartTime:null,
                    enteredCourseEndTime:null,
                    enteredBTWMinutes:null,
                    enteredCourseEndTime2:null,
                    enteredCourseStartTime2:null,
                    enteredBTWMinutes2:null,
                    note:null
                },
                this.selectLocation(this.locationId)
                this.$refs.mymodal.openModal()
            },

            async save(){
                if(this.insave){return}
                this.responseMessage=null
               // if(!this.$refs.balform.checkValidity()){return}
                    if(this.locationId==0 || this.courseId==-1 || this.procedureId==-1 ){
                        this.responseMessage="Please select a location, course and training"
                        return
                    }
                    if(this.studentId==-1 || this.instructorId==-1  ){
                        this.responseMessage="Please select a student and instructor"
                        return
                    }
                    if(this.selectedTimeData.enteredCourseStartTime==null || this.selectedTimeData.enteredCourseEndTime==null){
                        this.responseMessage="Please enter course start and end times before submitting"
                        return
                    }
                    var sdate = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime)
                    var edate = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime)
                    if( sdate > edate){
                        this.responseMessage = "Please enter a course end time AFTER your course start time"
                        return
                    }

                    var date1= new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime)
                    var date2 = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime)
                    
                    var courseMinutes = date1-date2
                    courseMinutes= (courseMinutes / (60000));
                    console.log(this.selectedTimeData.enteredBTWMinutes)
                    if(this.showBTWDropDown && this.showBTW){
                        if(this.selectedTimeData.enteredBTWMinutes===null || this.selectedTimeData.enteredBTWMinutes===''){
                            this.responseMessage="Please select BTW minutes"
                            return
                        }
                    }
                    

                    if(courseMinutes<this.selectedTimeData.enteredBTWMinutes){
                        this.responseMessage="Please enter BTW minutes less than or equal to the total course time."
                        return
                    }

                    //if its a combo, two items
                    if(this.isComboSequence){
                        var sdate2 = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime2)
                        var edate2 = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime2)
                        if( sdate2 > edate2){
                            this.responseMessage = "Please enter a road course end time AFTER your road course start time"
                            return
                        }

                        if((sdate2 < edate && sdate2 > sdate)||(sdate<edate2 && sdate >sdate2)){
                            this.responseMessage = "Please enter non-overlapping times"
                            return
                        }

                        var date3= new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime2)
                        var date4 = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime2)
                        
                        var courseMinutes2 = date3-date4
                        courseMinutes2= (courseMinutes2 / (60000));

                        if(this.showBTWDropDown && this.showBTW){
                            if(this.selectedTimeData.enteredBTWMinutes2===null || this.selectedTimeData.enteredBTWMinutes2===''){
                                this.responseMessage="Please select road BTW minutes"
                                return
                            }
                         }   

                        if(courseMinutes2<this.selectedTimeData.enteredBTWMinutes2){
                            this.responseMessage="Please enter BTW minutes less than or equal to the total course time."
                            return
                        }
                    }


                    

                    //note
                    if(this.noteCharCount>0){
                        if (this.selectedTimeData.note==null){
                            this.responseMessage="Please enter a note of at least " + this.noteCharCount + " characters."
                            return
                        }
                        if (this.selectedTimeData.note.length< this.noteCharCount){
                            this.responseMessage="Please enter a note of at least " + this.noteCharCount + " characters."
                            return
                        }

                    } 

                    this.insave=true
                    //if we are warning about time conflicts then do it here. Then we'll do the actual save if its confirmed.
                    if(this.warnAboutTimeConflicts){this.checkForTimeConflicts();return;}

                   var timeitem={
                    StudentID:this.studentId,
                    StudentName:this.studentName,
                    LocationID:this.locationId,
                    courseId:this.courseId,
                    Procedure:{id:this.procedureId},
                    InstructorId:this.instructorId
                   }

                    let req = {
                        ProcedureInstance:timeitem,
                        timeData:this.selectedTimeData
                    }
                    this.$store.dispatch('beforeAPI');
                    var response = await this.callAPI(req,'savenewtime')
                    //handle response
                    if(response.Successful){
                        this.insave=false
                        this.pleaseWait=false; 
                        this.inTimeUpdate=false 
                        this.$emit('refresh')
                        this.$refs.mymodal.closeModal()      
                    }else{
                        this.insave=false
                        this.responseMessage = response.Message
                    }
                    this.pleaseWait=false; 
        
            },
            async checkForTimeConflicts(){
                var timeitem={
                    StudentID:this.studentId,
                    StudentName:this.studentName,
                    LocationID:this.locationId,
                    courseId:this.courseId,
                    Procedure:{id:this.procedureId},
                    InstructorId:this.instructorId
                   }
                let req = {
                    ProcedureInstance:timeitem,
                    timeData:this.selectedTimeData,
                
                }
                this.$store.dispatch('beforeAPI');
        
                var response = await this.callAPI(req,'checkprocedureinstancetime')
                //handle response
                if(response.Successful){
                    //if no time conflicts, just go ahead and finish the submit
                    if(response.timeConflictList.length==0){this.finishSubmit(); return;}

                    //show the conficts
                    this.insave=false
                    this.timeConflictList= response.timeConflictList

                    let mythis = this
                    this.timeConflictList.forEach(myFunction2)
                    function myFunction2(item, index, arr) {
                        let comp=arr[index]
                        comp.evalStartTime=mythis.$options.filters.formatTimeForInput(comp.evalStartTime)
                        comp.evalEndTime=mythis.$options.filters.formatTimeForInput(comp.evalEndTime)
                    }

                    this.$refs.confirmsubmittime.Show()
                
                            
                }
                this.pleaseWait=false; 
            },
           async finishSubmit(){
            var timeitem={
                    StudentID:this.studentId,
                    StudentName:this.studentName,
                    LocationID:this.locationId,
                    courseId:this.courseId,
                    ProcedureSequenceId:this.procedureId,
                    InstructorId:this.instructorId
                   }

                    let req = {
                        ProcedureInstance:timeitem,
                        timeData:this.selectedTimeData
                    }
                    this.$store.dispatch('beforeAPI');
                    var response = await this.callAPI(req,'savenewtime')
                    //handle response
                    if(response.Successful){
                        this.pleaseWait=false; 
                        this.insave=false 
                        this.$emit('refresh')
                        this.$refs.mymodal.closeModal()      
                    }else{
                        this.insave=false
                        this.responseMessage = response.Message
                    }
                    this.pleaseWait=false; 
           },
            selectLocation(id){
                this.locationId=id
                this.studentList=[]
                this.getCourses();
                this.getInstructors()
                
            },
            async getCourses(){
                let req = {
                    LocationID:this.locationId
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'courses')
                //handle response
                if(response.Successful){
                    this.courseList=response.TimeEventList
                    this.courseId=-1
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
          },
          async getProcedureTypes(){
                let req = {
                    id:this.courseId
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'proceduretypes')
                //handle response
                if(response.Successful){
                    this.ProcedureTypeList=response.ProcedureTypeList
                    if(this.ProcedureTypeList.length==1){
                        this.selectProcedureType(this.ProcedureTypeList[0].id)
                    }
                    
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
          },
          async getProcedures(){
                let req = {
                    id:this.procedureTypeId
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'procedures')
                //handle response
                if(response.Successful){
                    this.procedureList=response.ProcedureList
                    this.procedureId=-1
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
          },
          async getInstructors(){
   
            let req = {
                LocationID:this.locationId
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'instructors')
            //handle response
            if(response.Successful){
                 this.instructorList=response.PersonList
  
                
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          },
            selectCourse(id){
                this.studentList=[]
                this.procedureList=[]
                this.allowEnterTruckNumber=false
                this.courseId=id
                this.getProcedureTypes();
            },
            selectinstructor(id){
                this.instructorId=id
            },
            selectProcedureType(id){
                console.log('seltype ' + id)
                this.procedureTypeId=id
                this.getProcedures()
            },
            selectprocedure(id){
                var show=true;
                var entertruck=false;
                var combo=false;
                var btw=false;
                this.procedureId = id
                

                //determin if we show station
                this.procedureList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if(comp.id==id){
                        show = comp.allowSelectLocationStation
                        entertruck=comp.allowEnterTruckNumber
                        combo=comp.isComboSequence
                        btw=comp.showBTW
                    }
                }
                this.allowEnterTruckNumber=entertruck;
                this.showTrucks=show && !entertruck;
                this.isComboSequence=combo;
                this.showBTW=btw
                this.getStudents();
            },
            selectstudent(id,name){
                this.studentId=id
                this.studentName=name
            },
            async getStudents(){
                let req = {
                    locationId:this.locationId,
                    RestrictToNotClockedInNow:true,
                    procedureSequenceId:this.procedureId,
                    procedureTypeId:this.procedureTypeId,
                    isManual:this.isManualTime,
                    override_AllRules:this.showAllStudents
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'studentsforinout')
                //handle response
                if(response.Successful){
                    this.studentList=response.PersonList
                    
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
          },
           
            
        }
    }
    </script>
    
    <style>
    
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    